import "lity";
import { fitVidsBlocks } from "./../util/fitVids";
import { mobileMenuToggle } from "./../util/mobileMenu";

export default {
	init() {
		// JavaScript to be fired on all pages
		mobileMenuToggle();
		fitVidsBlocks();
	},
	finalize() {
		// JavaScript to be fired on all pages, after page specific JS is fired
	}
};
